import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Link } from 'gatsby';

function Agtech() {
  return (
    <Container className='hp-page'>
      <Row className='d-flex justify-content-center mx-3'>
        <Col
          xs={8}
          md={3}
          className='d-flex justify-content-center align-items-center pb-3 xs-pb-0'
        >
          <a href='https://agritechnz.org.nz/' title='AgriTech New Zealand'>
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'brands/agritech-logo.svg'
              }
              alt='AgriTech New Zealand Member'
              fluid
              width='100%'
            />
          </a>
        </Col>
        <Col
          xs={8}
          md={7}
          className='d-flex justify-content-center align-items-center'
        >
          <span>
            <h5>HortPlus are a proud member of Agritech New Zealand</h5>
            <p>
              Agritech New Zealand helps connect, promote and advocate for
              shared interests in the primary sector and the use of smart and
              innovative technology for sustainable agriculture.
            </p>
          </span>
        </Col>
      </Row>
    </Container>
  );
}

export default Agtech;
