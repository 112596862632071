import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { useMediaQuery } from "react-responsive";
import "./Frame.scss";

function Frame5() {
  const isMobile = useMediaQuery({ query: `(max-width: 899px)` });

  return (
    <Container fluid className="px-0 hp-background-video">
      <div className="hp-text-overlay">
        <Container>
          <Row className="text-left text-md-center px-0">
            <Col md={{ span: 6, offset: 3 }}>
              <h1 className="hp-title hp-title--subtitle pb-5">
                Achieve Sustainability Goals
              </h1>
              <p>Sustainability is a core value for HortPlus.</p>
              <p>
                Everyone is at a different stage on their sustainability journey. By working
                together we can enable sustainable operation and resource use by encouraging the adoption of integrated management
                practices like Integrated Pest Management (IPM) and
                agroecological crop protection.
              </p>
              <p>
                Our decision support tools play a key role in demonstrating
                trust and responsibility to consumers, helping growers and
                industries achieve their sustainability objectives.
              </p>
            </Col>
          </Row>
          <Row className="pt-2 px-0 d-flex justify-content-center">
            <Button as={Link} to={"/sustainability"}>
              Our Sustainability Approach
            </Button>
          </Row>
        </Container>
      </div>
      {isMobile ? (
        <></>
      ) : (
        <div className="hp-background-video-embed">
          <video
            loop={true}
            autoPlay={true}
            poster={
              process.env.GATSBY_S3_BUCKET_ASSETS +
              "videos/apple_cover_video_poster.jpg"
            }
          >
            <source
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                "videos/apple_cover_video_trimmed.mp4"
              }
              type="video/mp4"
            ></source>
          </video>
        </div>
      )}
    </Container>
  );
}

export default Frame5;
