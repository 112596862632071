import React from 'react'
import CookieConsent from 'react-cookie-consent';

function CookieBar() {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      cookieName='cookie-hortplus'
      style={{ backgroundColor: '#ffffff', color: '#1a1a1a', fontSize: '14px' }}
      buttonStyle={{
        backgroundColor: '#007BFF',
        color: '#ffffff',
        fontSize: '13px',
        borderRadius: '4px',
        padding: '0.5rem 1rem'
      }}
      expires={1000}
    >
      We use cookies on our website to give you the most relevant experience. By
      clicking “Accept”, you consent to the use of cookies.
    </CookieConsent>
  );
}

export default CookieBar;
