import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

function Frame3() {
  return (
    <Container fluid className="hp-page hp-page--deep-blue-gradient px-0">
      <Container className="text-light">
        <Row className="align-items-center">
          <Col className="mb-3 mb-md-0">
            <h1 className="hp-title hp-title--subtitle text-light pb-5">
              A Bridge Between Science and Industry
            </h1>

            <p className="pr-5">
              HortPlus has strong working relationships with leading scientists
              and researchers in a range of institutions in New Zealand and
              internationally, including Plant &amp; Food Research as well as
              experts in agrichemicals, entomology, crop monitoring, and
              irrigation.
            </p>

            <p className="pr-5">
              We have a proven record delivering practical pathways for
              activating research and extension, as well as being a key partner
              with industry in the journey towards integrated production
              initiatives and sustainability.
            </p>

            <p className="pr-5">
              We strive to understand the underlying challenges that are being
              faced by growers and organisations and have been trusted to
              deliver technology solutions for over fifteen years.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                "images/plant_and_food_research_office.jpg"
              }
              alt="Plant and Food Research Office"
              fluid
            />
            <p className="hp-text--note pt-3">
              HortPlus collaborates closely with research institutions such as
              Plant &amp; Food Research.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Frame3;
