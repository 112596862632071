import React from 'react'
import { CardDeck, Container } from 'react-bootstrap';
import IntegrationCard from '../IntegrationCard/IntegrationCard';
import './Frame.scss';

function Frame8() {
  return (
    <Container fluid className='hp-page hp-page--blue-grey pb-4'>
      <Container fluid className='d-flex flex-column align-items-center'>
        <h1 className='hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5'>
          Connect Weather Stations &amp; Hardware
        </h1>
        <CardDeck className='hp-carddeck-width justify-content-center'>
          <IntegrationCard type={'harvest'} />
          <IntegrationCard type={'campbell'} />
          <IntegrationCard type={'metris'} />
          <IntegrationCard type={'ricado'} />
        </CardDeck>
        <h1 className='hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5'>
          Data Integrations and Modules
        </h1>
        <CardDeck className='hp-carddeck-width justify-content-center'>
          <IntegrationCard type={'property'} />
          <IntegrationCard type={'residues'} />
          <IntegrationCard type={'irrigation'} />
          <IntegrationCard type={'diseasemodels'} />
          <IntegrationCard type={'dailyreports'} />
        </CardDeck>
        <h1 className='hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5'>
          Spray &amp; Monitoring Integrations
        </h1>
        <CardDeck className='hp-carddeck-width justify-content-center'>
          <IntegrationCard type={'sprayview'} />
          <IntegrationCard type={'traplog'} />
          <IntegrationCard type={'sprayplanmanager'} />
          <IntegrationCard type={'spraylog'} />
        </CardDeck>
      </Container>
    </Container>
  );
}

export default Frame8;
