import React from 'react';
import { Row, Col, Container, Button, Image } from 'react-bootstrap';
import { Link } from 'gatsby';
import './Frame.scss';

function Frame4() {
  return (
    <Container fluid className='hp-page hp-page--blue-grey'>
      <Container className='px-0'>
        <Row noGutters>
          <Col md={5} className='pr-md-5 mx-3 mx-md-0'>
            <h1 className='hp-title hp-title--subtitle pb-3'>
              Innovative Technology
            </h1>
            <p className='pt-md-5'>
              Our platform is based on powerful science and data models to
              enable insight and inform management decisions for individual
              growers and agronomists, while also providing high-level overviews
              of regions and historical trends for managers and industry
              organisations.
            </p>
            <p>
              We use a modular system architecture and design process to
              customise the MetWatch Platform to specific business and industry
              challenges. We are able to quickly build dashboards for new concepts and
              iterate projects to be responsive to changing needs and
              requirements, such as Integrated Production Programmes and GLOBAL
              G.A.P.
            </p>
            <p>
              Our systems are cloud-based and highly secure and developed for
              ease of use with the latest in responsive web technology.
            </p>

            <Row noGutters className='pt-2 px-0 d-flex justify-content-center'>
              <Button as={Link} to={'/demo'}>
                Request a Demo
              </Button>
            </Row>
          </Col>
          <Col xs={12} md={6} className='pt-5 text-center'>
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS + 'images/notification.png'
              }
              alt='Notification 1'
              className='hp-notification-1-position'
              fluid
              width='359'
              height='232'
            />

            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'images/iphone_metwatch_app.png'
              }
              alt='iphone'
              className='hp-phone-position'
              fluid
              width='307'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Frame4;
