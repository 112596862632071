import React from "react";
import "./Frame.scss";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import "../ArticleCard/ArticleCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

function Frame9(props) {
  const postList = props.postList;
  return (
    <Container fluid className="hp-page hp-page--blue-grey pb-4">
      <Container fluid className="px-1 py-5">
        <h1 className="hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5">
          Latest News
        </h1>
        <Row className="d-flex flex-wrap justify-content-center mx-2">
          {postList.edges.map(({ node }, i) => (
            <Col key={i} lg={5} className="mx-3 mb-3 px-0">
              <div
                className="hp-article-card"
                style={i % 2 ? { float: "left", maxWidth: '420px' } : { float: "right", maxWidth: '420px' }}
              >
                <Link to={node.fields.slug} key={i} className="hp-article">
                  {node.frontmatter.image && (
                    <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                  )}
                  <Container>
                    <Row className="py-3">
                      <Col xs={12} md={11} className="mx-2">
                        <h1 className="hp-article-title">
                          {node.frontmatter.title}
                        </h1>
                        <p>{node.excerpt}</p>
                        <small>{node.frontmatter.date}</small>
                      </Col>
                      <Col
                        xs={1}
                        className="align-items-center justify-content-center mx-2 d-none d-md-flex"
                      >
                        <FontAwesomeIcon size="2x" icon={faChevronRight} />
                      </Col>
                    </Row>
                  </Container>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="mt-4 mx-2">
          <Col className="text-center">
            <Button as={Link} to={"/news"}>See All</Button>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Frame9;
