import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap';

function Frame7() {
  return (
    <Container className='hp-padding-100'>
      <h1 className='hp-title hp-title--subtitle d-block text-center pb-5'>
        Our Platform Integrates
        <br />
        With Your Technology
      </h1>
      <Row noGutters>
        <Col md={5} className='d-block text-center mb-4 mb-md-0 mx-3 mx-md-auto'>
          <Image
            src={
              process.env.GATSBY_S3_BUCKET_ASSETS +
              'icons/green_weather_station_icon.svg'
            }
            alt='Weather Station Icon'
            className='color-hp-green'
          />
          <h6 className='hp-title hp-title--subtitle-half text-hp-green pt-5'>
            Connect Your Weather Station
          </h6>
          <p className='pt-4'>
            Connect your own weather station hardware and networks of stations
            to enhance insight in specific locations.
          </p>
          <p className='pt-2'>
            The MetWatch Platform is compatible with a range of weather station
            providers and sensor equipment, including Harvest Weather Stations.
          </p>
        </Col>
        <Col md={{ span: 5, offset: 2 }} className='d-block text-center mx-3 mx-md-auto'>
          <Image
            src={
              process.env.GATSBY_S3_BUCKET_ASSETS +
              'icons/blue_integrate_icon.svg'
            }
            alt='Integration Icon'
          />
          <h6 className='hp-title hp-title--subtitle-half pt-5'>
            Bring Your Data Sources Together
          </h6>
          <p className='pt-4'>
            We can integrate with your existing tools, user management and
            business intelligence systems and processes to connect data
            including spray diaries, irrigation monitoring, and property information.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Frame7;
