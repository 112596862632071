import React from 'react';
import { Container } from 'react-bootstrap';
import './GetInTouch.scss';

function GetInTouch() {
  return (
    <Container className='hp-page d-block text-center hp-font-28'>
      <header className='hp-title hp-title--subtitle'>Get in Touch</header>
      <a className='text-dark' href='mailto:support@hortplus.com?subject=HortPlus'>
        <b>support@hortplus.com</b>
      </a>
    </Container>
  );
}

export default GetInTouch;
