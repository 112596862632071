import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Link } from "gatsby";

function Frame1() {
  return (
    <>
      <Container fluid className="hp-page hp-page--blue-dark px-0">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column text-center text-light mt-0 mt-md-4 mt-lg-5">
              <h1 className="hp-title text-light text-left">
                We Build Weather &amp; Disease Platforms for Agriculture
              </h1>
              <h5 className="hh5-text text-left pt-3">
                Our goal is to provide growers, agronomists, and researchers
                with the best tools to enable more sustainable agriculture.
              </h5>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <div className="hp-weather-machine">
                <Image
                
                  src={
                    process.env.GATSBY_S3_BUCKET_ASSETS +
                    "images/weather_machine.png"
                  }
                  height="540"
                  width="540"
                  alt="Weather Machine"
                  fluid
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="pt-5">
        <div class="alert alert-success" role="alert"> <h4 class="alert-heading">📣 Our Latest Project</h4><p>Understand the impact of climate change on your grape and apple crops with the new Changing Climate: Disease Risks &amp; Costs tool.</p>
            <p>Created for the Our Land and Water National Science Challenge in collaboration with Plant &amp; Food Research, NIWA, The Agribusiness and Economics Research Unit and Applied Research &amp; Technologies.</p>
            <a href="https://olw.metwatch.nz/" class="btn btn-primary btn-lg mt-2" role="button" aria-disabled="true">Access Changing Climate: Disease Risk &amp; Costs tool</a>
        </div>
        <Row className="py-5">
          <Col xs={12} md={6}>
            <h3 className="hp-blue-idea">
              HortPlus has pioneered the development of digital decision support
              tools for agriculture.
            </h3>
            <h3 className="hp-blue-idea pt-4">
              Our digital platform is powered by a{" "}
              <Link to="/weather-data">robust weather data network</Link> and{" "}
              <Link to="/technology">innovative technology</Link>, providing a
              bridge between science and industry
              <span class="hp-blue-final">
                {" "}
                to help on the sustainability and IPM journey.
              </span>
            </h3>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Frame1;
