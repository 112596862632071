import React from 'react';
import { Link } from 'gatsby';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import './Frame.scss';

function Frame6() {
  const backgroundImageStyle = {
    backgroundImage: `url(${
      process.env.GATSBY_S3_BUCKET_ASSETS + 'images/nz_hills.jpg'
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center left'
  };

  return (
    <Container fluid className='hp-page p-0' style={backgroundImageStyle}>
      <Container>
        <Row>
          <Col xs={12} className='hp-padding-100 hp-background-image--portrait'>
            <Container className='px-0'>
              <Row noGutters>
                <Col md={{ span: 8, offset: 2 }}>
                  <h1 className='hp-title--subtitle hp-title d-block text-light text-center'>
                    New Zealand's Leading Network of Agricultural Weather
                    Stations
                  </h1>
                  <p className='d-block text-light pt-3 text-center'>
                    We have developed a network of scientific and research-grade
                    weather stations located in key growing regions throughout
                    New Zealand for current &amp; historical weather, as well as
                    using the power of the IBM Weather Company, MetService, and
                    other vendors to enable highly accurate localised forecasts
                    in New Zealand and globally.
                  </p>

                  <Row
                    noGutters
                    className='pt-2 px-0 d-flex justify-content-center'
                  >
                    <Button as={Link} to={'/weather-data'}>
                      See Our Weather Network
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} className='bg-white mb-md-5'>
            <Container className='d-grid justify-content-center align-items-center py-5'>
              <Row md={{ span: 8, offset: 2 }} className='d-block text-center'>
                <h6 className='hp-title hp-title--subtitle-half'>
                  Weather Data &amp; Forecasts
                </h6>
              </Row>
              <Row md={{ span: 6, offset: 2 }} className='pt-3'>
                <ul className='hp-feature-list'>
                  <li>
                    High precision hourly weather forecasts up to 15 days in key
                    growing areas and at a property-level to inform grower
                    actions
                  </li>
                  <li>
                    Key weather variables including temperature, rain, wind and
                    relative humidity
                  </li>
                  <li>
                    Agricultural variables including soil moisture,
                    evapotranspiration, dew point, leaf wetness
                  </li>
                  <li>
                    Historical research-quality weather data going back up to
                    twenty years
                  </li>
                  <li>
                    API access to weather data feed is available for advanced
                    integration and functions
                  </li>
                </ul>
              </Row>
              <Row
                md={6}
                className='pt-5 d-flex justify-content-center align-items-center text-center'
              >
                <Col xs={4}>
                  <Image
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS +
                      'brands/the_weather_company.png'
                    }
                    alt='IBM The Weather Company'
                    fluid
                    width='180'
                  />
                </Col>
                <Col xs={4}>
                  <Image
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS +
                      'brands/metservice2.png'
                    }
                    alt='MetService'
                    fluid
                    width='180'
                  />
                </Col>
                <Col xs={4}>
                  <Image
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/aus_gov.png'
                    }
                    alt='Australia Government'
                    fluid
                    width='180'
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Frame6;
