import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import './ContactDetails.scss';

function ContactDetails(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const style = {
    position: 'relative',
    width: '100%',
    height: '400px'
  };

  return (
    <Container className='pb-5 pt-0 d-flex flex-column justify-content-center align-items-center'>
      <Row noGutters>
        <Col className='hp-page--blue-grey m-3'>
          <Map
            google={props.google}
            initialCenter={{
              lat: -41.282896301887334,
              lng: 174.777598255194
            }}
            zoom={18}
            containerStyle={style}
            className='hp-map-card'
          >
            <Marker
              title={'HortPlus, Wellington'}
              name={'HortPlus, Wellington'}
              position={{ lat: -41.282896301887334, lng: 174.777598255194 }}
            />
          </Map>
          <header className='hp-title--subtitle-half hp-bold-weight py-3 pl-5'>
            Wellington
          </header>
          <p className='pl-5 pb-3'>
            <b>HortPlus</b>
            <br /> Level 1 Aviation House,
            <br /> 12 Johnston Street,
            <br /> Wellington 6011,
            <br /> New Zealand
          </p>
        </Col>
        <Col className='hp-page--blue-grey m-3'>
          <Map
            google={props.google}
            initialCenter={{
              lat: -39.6245496,
              lng: 176.8786344
            }}
            zoom={15}
            containerStyle={style}
            className='hp-map-card'
          >
            <Marker
              title={'HortPlus, Hawkes Bay'}
              name={'HortPlus, Hawkes Bay'}
              position={{ lat: -39.6245496, lng: 176.8786344 }}
            />
          </Map>
          <header className='hp-title--subtitle-half hp-bold-weight pl-5 py-3'>
            Hawkes Bay
          </header>
          <p className='pl-5 pb-3'>
            <b>HortPlus</b>
            <br />
            21 Ruahapia Road,
            <br />
            RD 10 Waipatu,
            <br />
            Hastings 4180,
            <br />
            New Zealand
          </p>
        </Col>
      </Row>
    </Container>
  );
}

// This prevents the default text 'Loading...' from showing
const LoadingContainer = () => <></>;

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY,
  LoadingContainer: LoadingContainer
})(ContactDetails);
