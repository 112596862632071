import React, { useEffect } from 'react';
import reportWebVitals from '../reportWebVitals';
import '../styles/homepage.scss';

// Import Bootstrap and its default variables
import 'fontsource-poppins';
import Agtech from '../components/HomepageFrames/Agtech';
import Frame1 from '../components/HomepageFrames/Frame1';
import Brands from '../components/Brands/Brands';
import Frame2 from '../components/HomepageFrames/Frame2';
import Frame3 from '../components/HomepageFrames/Frame3';
import Frame4 from '../components/HomepageFrames/Frame4';
import Frame5 from '../components/HomepageFrames/Frame5';
import EndorsementCards from '../components/EndorsementCards/EndorsementCards';
import Frame6 from '../components/HomepageFrames/Frame6';
import Frame7 from '../components/HomepageFrames/Frame7';
import Frame8 from '../components/HomepageFrames/Frame8';
import Frame9 from '../components/HomepageFrames/frame9';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import ContactDetails from '../components/ContactDetails/ContactDetails';
import CookieBar from '../components/CookieBar/CookieBar';

/** Home page for the application */
export function Homepage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const postList = props.data.allMarkdownRemark;

  return (
    <>
      <Frame1 />
      <Brands />
      <Frame2 />

      <Frame3 />
      <Agtech />
      <Frame5 />
      <Frame4 />

      <Frame6 />
      <Frame7 />

      <Frame9 postList={postList}/>

      {/* <Frame8 /> */}
      <GetInTouch />
      <ContactDetails />

      <CookieBar />
    </>
  );
}

export const listQueryArticles = graphql`
  query ListQueryArticles {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 4
      ) 
      {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            image {
              childImageSharp {
                resize(width: 400, height: 320) {
                  src
                }
                fluid(maxWidth: 520) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Homepage;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
