import React from "react";
import { Container, Nav } from "react-bootstrap";
import "./Brands.scss";

function Brands() {
  return (
    <>
      <Container fluid className="px-5">
        <Nav className="justify-content-center align-items-center pb-3">
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.applesandpears.nz/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/nz_apples_and_pears.png"
                }
                alt="NZ Apples &amp; Pears Logo"
                width="120"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.summerfruitnz.co.nz/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/summerfruit_nz.png"
                }
                alt="Summerfruit NZ Logo"
                width="122"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.onionsnz.com/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS + "brands/onions_nz.png"
                }
                alt="NZ Onions Logo"
                width="128"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item" target="_blank">
            <Nav.Link href="https://www.pggwrightson.co.nz/Services/Fruitfed-Supplies">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/fruitfed_supplies.png"
                }
                alt="Fruitfed Supplies Logo"
                width="133"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.far.org.nz/" target="_blank">
              <img
                src={process.env.GATSBY_S3_BUCKET_ASSETS + "brands/far.png"}
                alt="HortPlus Logo"
                width="96"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.watties.co.nz/" target="_blank">
              <img
                src={process.env.GATSBY_S3_BUCKET_ASSETS + "brands/watties.png"}
                alt="Watties Logo"
                width="139"
              />
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://a-lighter-touch.co.nz/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/a_lighter_touch.png"
                }
                alt="A Lighter Touch"
                width="150"
              />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>

      <Container fluid className="px-5">
        <Nav className="justify-content-center align-items-center pb-3">
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.freshvegetables.co.nz/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/VegetablesNZ_logo-cropped.png"
                }
                alt="Fruition"
                width="130"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.fruitionhort.com" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/fruitionhort-green.svg"
                }
                alt="Fruition"
                width="130"
              />
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://nzppi.co.nz/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS + "brands/nzppi_logo.svg"
                }
                alt="NZPPI"
                width="150"
              />
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link
              href="https://www.nzwine.com/en/sustainability/swnz"
              target="_blank"
            >
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS + "brands/swnz_logo.jpg"
                }
                alt="SWNZ"
                width="100"
              />
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.zespri.com/" target="_blank">
              <img
                src={process.env.GATSBY_S3_BUCKET_ASSETS + "brands/zespri.png"}
                alt="Zespri Logo"
                width="101"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://www.plantandfood.co.nz/" target="_blank">
              <img
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "brands/plant_and_food_research.png"
                }
                alt="Plant and Food Research Logo"
                width="147"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="hp-brands-nav__item">
            <Nav.Link href="https://kvh.org.nz/" target="_blank">
              <img
                src={process.env.GATSBY_S3_BUCKET_ASSETS + "brands/kvh.png"}
                alt="Kiwifruit Vine Health Logo"
                width="90"
              />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </>
  );
}

export default Brands;
