import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

function Frame2() {
  return (
    <Container className="hp-page pb-5">
      <Row noGutters>
        <Col md={6} className="pr-md-5 pr-0 mb-3 mb-md-0 mx-3 mx-md-0">
          <h1 className="hp-title hp-title--subtitle">
            A Decision Support Platform
          </h1>
          <p>
            The <Link to="/technology"> HortPlus MetWatch Platform</Link>{" "}
            provides practical and easy-to-use tools for digital decision
            support. The MetWatch Platform provides a framework for data-driven IPM decision-making.
          </p>

          <ul>
            <li>
              <Link to="/disease-models">Pest &amp; Disease</Link>
            </li>
            <li>Spray planning</li>
            <li>Irrigation &amp; soil use</li>
            <li>Crop planning &amp; management</li>
          </ul>
          <p>
            MetWatch is highly extendable and can be customised to your
            organisation's needs to integrate with existing infrastructure,
            on-property hardware, and business intelligence systems for a
            central point of truth.
          </p>

          <Row
            noGutters
            className="pt-2 px-0 mb-2 mb-xs-0 d-flex justify-content-center"
          >
            <Button as={Link} to={"/technology"}>
              Find Out More
            </Button>
          </Row>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <Image
            src={
              process.env.GATSBY_S3_BUCKET_ASSETS + "images/woman_spinach.jpg"
            }
            alt="Digital Grower Tools for Agriculture"
            fluid
            width="100%"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Frame2;
